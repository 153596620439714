import {useParams} from "react-router-dom";

export default function Feature({slug, feature}) {

    return (
        <div
            className="py-7 md:py-16 flex flex-col md:flex-row items-center gap-6 md:gap-14 md:px-12 md:even:flex-row-reverse group"
        >
            <picture className="flex items-center justify-center w-full md:w-[560px] flex-shrink-0 relative px-3 md:px-0">
                <img src={import.meta.env.VITE_API_URL + '/' + feature.feature_image}
                     alt={`${feature.feature_title} Görseli`}
                     className="md:max-h-[440px] w-full md:w-auto max-w-full object-cover"/>
            </picture>
            <div className="text-[#4b4b4b] px-4 md:px-0">
                {!slug && (
                    <div
                        className="inline-flex px-3 md:px-4 h-7 md:h-8 items-center mb-2 rounded-full bg-[#22c55f] text-white text-sm md:text-[15px]">
                        {feature.category_title}
                    </div>
                )}
                <h3 className="text-xl md:text-3xl font-semibold mb-2">{feature.feature_title}</h3>
                <p className="text-[#727272] text-base md:text-xl font-light">
                    {feature.feature_description}
                </p>
            </div>
        </div>
    )
}