import Yup from "./validation"

export const carValidation = Yup.object().shape({
  car_plate: Yup.string()
    .required(),
  car_brand: Yup.string()
    .required(),
  car_model: Yup.string()
    .required(),
  car_gps_id: Yup.string()
});
