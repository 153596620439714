import Home from "~/pages/web/home";
import Features from "~/pages/web/features";
import Prices from "~/pages/web/prices";
import Contact from "~/pages/web/contact";
import Page404 from "~/components/404";
import {Outlet} from "react-router-dom";
import FeatureCategory from "~/pages/web/features/category/index.jsx";
import Page from "~/pages/web/page/index.jsx";
import SmartControlSystem from "~/pages/web/smart-control-system/index.jsx";
import Invoice from "~/pages/web/invoice/index.jsx";

const routes = [
	{
		index: true,
		element: <Home/>
	},
	{
		path: 'features',
		element: <Outlet/>,
		children: [
			{
				index: true,
				element: <Features />
			},
			{
				path: ':slug',
				element: <FeatureCategory />
			}
		]
	},
	{
		path: 'invoice/:id',
		element: <Invoice />
	},
	{
		path: 'p/:slug',
		element: <Page />
	},
	{
		path: 'prices',
		element: <Prices/>
	},
	{
		path: 'contact',
		element: <Contact/>
	},
	{
		path: 'wise-chat',
		element: <SmartControlSystem />
	},
	{
		path: '*',
		element: <Page404/>
	}
];

export default routes
