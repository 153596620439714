import slugify from "slugify";
import parsePhoneNumber from 'libphonenumber-js'
import i18n from "~/utils/i18n.js";

export const phoneFormat = phone => {
	const phoneNumber = parsePhoneNumber(phone)
	return phoneNumber?.formatInternational() || phone
}

export const slug = string => slugify(string, {
	lower: true,
	locale: 'tr',
	trim: true,
	remove: /[^a-zA-Z0-9-\s]+/g
})

export const moneyFormat = (money, currency = 'TRY', locale = 'tr-TR', minimumFractionDigits = 2) => {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		minimumFractionDigits,
		currency
	}).format(money)
}

export const getNamePrefix = (name, slice = 1) => {
	if (!name) return ''
	return String(name)
		.split(' ')
		.map(word => word.charAt(0).toUpperCase())
		.join('')
		.slice(0, slice);
}

export const getStatusColor = id => {
	switch (id) {
		default:
		case 1:
			return '#3850c4'
		case 2:
			return '#1c507e'
		case 3:
			return '#4cafac'
		case 4:
			return '#4caf50'
		case 5:
			return '#000'
		case 6:
			return '#887016'
		case 7:
			return '#f80404'
	}
}

export function calculateTime(minutes) {
	if(minutes < 60) {
		return i18n.t('{{minutes}} dakika boşluk var', { minutes });
	} else {
		let hours = Math.floor(minutes / 60);
		let remainingMinutes = minutes % 60;
		return i18n.t('{{hours}} saat {{minutes}} dakika boşluk var', { hours, minutes: remainingMinutes });
	}
}

export function appointmentServices(services) {
	let s = services && services.reduce((acc, curr) => {
		if (!acc.includes(curr.group_name)) {
			acc.push(curr.group_name)
		}
		return acc
	}, []) || []
	return s.length > 0 ? s.join(', ') : i18n.t('Seçilmedi')
}

export function numberFormat(number) {
	return new Intl.NumberFormat('tr-TR').format(
		number
	)
}

export function calculateColorIntensity(max, min, value) {
	// Yeşilin tonunu sabit tut (Yeşil için yaklaşık 120)
	const hue = 120;
	// Doygunluğu ve aydınlığı ayarla, pastel tonlar için doygunluk ve aydınlık sabit
	const saturation = 30; // Doygunluk yüzdesi
	const lightnessMin = 70; // Minimum aydınlık yüzdesi
	const lightnessMax = 95; // Maksimum aydınlık yüzdesi

	// Aydınlık değerini hesapla
	const lightness = (value === 0) ? 255 : lightnessMin + ((value - min) / (max - min)) * (lightnessMax - lightnessMin);

	// HSL değerini döndür
	return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function getYoutubeImage(url) {
	const parsedUrl = new URL(url)
	return `https://i.ytimg.com/vi/${parsedUrl.searchParams.get('v')}/maxresdefault.jpg`
}

export function getYoutubeUrl(url) {
	const parsedUrl = new URL(url)
	return parsedUrl.searchParams.get('v')
}

export function replaceVariables(input, variables) {
	return input.replace(/{{(\d+)}}/g, (match, key) => {
		if (variables.hasOwnProperty(key) && variables[key]) {
			return variables[key];
		}
		return match;
	});
}