import Yup from "./validation"

export const offDayValidation = Yup.object().shape({
    off_day_entity_type: Yup.string()
        .oneOf(['driver', 'car'])
        .required(),
    off_day_entity_id: Yup.string()
        .required(),
    off_day_date: Yup.string()
        .required()
});
