import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from "dayjs/plugin/localizedFormat"
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en'
import 'dayjs/locale/tr'
import 'dayjs/locale/de'
import 'dayjs/locale/ar'
import 'dayjs/locale/da'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/es'
import 'dayjs/locale/nl'
import 'dayjs/locale/ja'
import 'dayjs/locale/ru'
import 'dayjs/locale/ko'
import 'dayjs/locale/pl'
import 'dayjs/locale/lv'
import 'dayjs/locale/fi'
import 'dayjs/locale/pt'
import 'dayjs/locale/sw'
import 'dayjs/locale/zh-cn'

import dayjs from "dayjs";
import i18n from "~/utils/i18n.js";

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(relativeTime)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.locale('tr')

export {
  dayjs
}

export function calculateTimeDifference(date, date2) {
  let now = dayjs(date2 || new Date());
  let past = dayjs(date);

  let difference = now.diff(past, 'second');

  let hours = Math.floor(difference / 3600);
  difference = difference % 3600;
  let minutes = Math.floor(difference / 60);
  let seconds = difference % 60;

  let result = '';
  if (hours > 0) result += `${hours}sa `;
  if (minutes > 0) result += `${minutes}dk `;
  if (seconds > 0) result += `${seconds}sn`;

  return result.trim();
}

export function calculateAppointmentTime(date1, date2) {
  let now = dayjs(date2);
  let past = dayjs(date1);

  let difference = now.diff(past, 'second');

  let hours = Math.floor(difference / 3600);
  difference = difference % 3600;
  let minutes = Math.floor(difference / 60);
  let seconds = difference % 60;

  let result = '';
  if (hours > 0) result += `${hours}${i18n.t('sa')} `;
  if (minutes > 0) result += `${minutes}${i18n.t('dk')} `;
  if (seconds > 0) result += `${seconds}${i18n.t('sn')}`;

  return result.trim();
}

function formatRemainingTime(remaining_time) {
  const years = remaining_time.years();
  const months = remaining_time.months();
  const days = remaining_time.days();
  const hours = remaining_time.hours();
  const minutes = remaining_time.minutes();
  const seconds = remaining_time.seconds();

  let formatted = '';

  if (years > 0) {
    formatted += `${years}yıl `;
  }
  if (months > 0) {
    formatted += `${months}ay `;
  }
  if (days > 0) {
    formatted += `${days}gü `;
  }
  if (hours > 0) {
    formatted += `${hours}sa `;
  }
  if (minutes > 0) {
    formatted += `${minutes}dk `;
  }
  if (seconds > 0) {
    formatted += `${seconds}sn`;
  }

  return formatted;
}

export const checkSubscription = (start_date, end_date) => {
  const current_time = dayjs();
  const start = dayjs(start_date);
  const end = dayjs(end_date);
  const result = {};

  if (current_time.isBetween(start, end, 'second', '[]')) {
    const remaining_time = dayjs.duration(end.diff(current_time));
    const total_time = dayjs.duration(end.diff(start));
    const used_time = dayjs.duration(current_time.diff(start));

    const total_seconds = total_time.asSeconds();
    const used_seconds = used_time.asSeconds();

    const used_percentage = (used_seconds / total_seconds) * 100;

    result['usage_percentage'] = parseFloat(used_percentage.toFixed(2));

    result['remaining_time'] = formatRemainingTime(remaining_time);

    result['subscription_active'] = true;
  } else {
    result['status'] = "Abonelik süresi doldu veya başlamadı.";
    result['subscription_active'] = false;
  }

  return result;
}

export const timeAgo = date => dayjs(date).locale(i18n.language).fromNow()

export const dateFormat = (date, format = 'DD MMMM YYYY') => {
  return dayjs(date).add(3, 'hours').locale(i18n.language).format(format)
}

export function getAllDaysOfMonth(year, month) {
  const days = [];
  let currentDay = dayjs(new Date(year, month - 1, 1));
  while (currentDay.month() === month - 1) {
    days.push(currentDay.format('YYYY-MM-DD'));
    currentDay = currentDay.add(1, 'day');
  }
  return days;
}