import {createBrowserRouter} from "react-router-dom";
import Page404 from "~/components/404";
import {lazy, Suspense} from "react";
import FullScreenLoader from "~/components/full-screen-loader";
import Tablet from "~/pages/tablet";

import homeRoutes from "~/routes/web"
import App from "~/pages/web/app/index.jsx";
import PublicDashboard from "~/pages/dashboard";

const DriverLayout = lazy(() => import("~/layouts/driver"))
const WebLayout = lazy(() => import("~/layouts/web"))
const AppLayout = lazy(() => import("~/layouts/app"))
const AdminLayout = lazy(() => import("~/layouts/admin"))
const PanelLayout = lazy(() => import("~/layouts/panel"))

const routes = createBrowserRouter([
	{
		path: '/',
		element: <Suspense fallback={<FullScreenLoader />}><WebLayout /></Suspense>,
		errorElement: <Page404 />,
		children: homeRoutes
	},
	{
		path: '/dashboard/:token',
		element: <PublicDashboard />
	},
	{
		path: '/app',
		element: <App />
	},
	{
		path: '/tablet/:slug/*',
		element: <Tablet />
	},
	{
		path: '/driver/:username/*',
		element: <Suspense fallback={<FullScreenLoader />}><DriverLayout /></Suspense>,
		errorElement: <Page404 />
	},
	{
		path: '/:slug/*',
		element: <Suspense fallback={<FullScreenLoader />}><AppLayout /></Suspense>,
		errorElement: <Page404 />
	},
	{
		path: '/admin/*',
		element: <Suspense fallback={<FullScreenLoader />}><AdminLayout /></Suspense>,
		errorElement: <Page404 />
	},
	{
		path: '/panel/*',
		element: <Suspense fallback={<FullScreenLoader />}><PanelLayout /></Suspense>,
		errorElement: <Page404 />
	},
]);

export default routes
