import Yup from "./validation"

export const driverValidation = Yup.object().shape({
  driver_name: Yup.string()
    .required(),
  driver_identity: Yup.string()
    .required(),
  driver_phone: Yup.string()
    .required(),
  driver_car_id: Yup.string()
});
