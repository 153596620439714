import { configureStore } from '@reduxjs/toolkit'
import modal from "./modal"
import auth from "./auth"
import admin from "./admin"
import business from "./business"
import sale from "./sale"
import appointment from "./appointment"
import tablet from "~/store/tablet";
import app from "~/store/app";
import web from "~/store/web";
import driver from "~/store/driver";

export const store = configureStore({
	reducer: {
		modal,
		auth,
		admin,
		business,
		sale,
		appointment,
		tablet,
		app,
		web,
		driver,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	})
})
