import notify from "react-hot-toast"
import {store} from "~/store";

function parseData(data) {
    const formData = new FormData()
    console.log('formData', data)
    for (let [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && value !== null) {
            if (value instanceof File) {
                formData.append(key, value)
            } else {
                [...value].map(file => {
                    formData.append(key + '[]', file)
                })
            }
        } else if (Array.isArray(value)) {
            for (let file of value) {
                formData.append(`${key}${value.length > 1 ? '[]' : ''}`, file?.uploaded ? JSON.stringify(file) : file)
            }
        } else {
            formData.append(key, value)
        }
    }
    return formData
}

function request(url, method = 'get', data = {}, opts = {}, apiUrl = false) {
    return new Promise(async (resolve, reject) => {
        const options = {
            method,
            redirect: "follow"
        }
        const settings = {
            success: true,
            error: true,
            ...opts
        }
        if (!apiUrl) {
            const states = store.getState()
            if (states.auth?.token) {
                data.token = states.auth?.token
            }
            if (states.admin?.token) {
                data.adminToken = states.admin.token
            }
            if (states.driver?.driverToken) {
                data.driverToken = states.driver.driverToken
            }
            if (states.business?.staffToken) {
                data.staffToken = states.business?.staffToken
            }
            if (states.tablet?.tablet?.token) {
                data.tabletToken = states.tablet?.tablet?.token
            }
        } else {
            const states = store.getState()
            options.headers = {
                'W-Auth': states.business?.staffToken
            }
        }
        if (data && method === 'post') {
            options.body = settings?.json ? JSON.stringify(data) : parseData(data)
        }
        const result = await (await fetch(apiUrl ? `${apiUrl}/${url}` : `${import.meta.env.VITE_API_URL}/api/v1/${url}`, options)).json()
        if (result?.status === 200 || result?.code === 200 || result?.type === 'success') {
            if (result?.message && settings.success) {
                notify.success(result.message)
            }
            resolve(result)
        } else {
            if (result?.message && settings.error) {
                notify.error(result.message)
            }
            reject(result)
        }
    })
}

export const post = (url, data = {}, options = {}) => request(url, 'post', data, options);
export const get = (url, options = {}) => request(url, 'get', {}, options)
export const WISECRM_API = {
    post: (url, data = {}, options = {}) => request(url, 'post', data, options, 'https://chat-stage.wisecrm.io/api/v1'),
    get: (url, options = {}) => request(url, 'get', {}, options, 'https://chat-stage.wisecrm.io/api/v1')
}
