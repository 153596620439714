import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	driverToken: localStorage.getItem('driverToken') || null,
	driver: null
}

const driver = createSlice({
	name: 'driver',
	initialState,
	reducers: {
		_setDriver: (state, action) => {
			state.driver = action.payload
		},
		_setDriverToken: (state, action) => {
			state.driverToken = action.payload
			localStorage.setItem('driverToken', action.payload)
		},
		_removeDriver: state => {
			state.driver = null
			state.driverToken = null
			localStorage.removeItem('driverToken')
		}
	}
})

export const { _setDriverToken, _setDriver, _removeDriver } = driver.actions

export const useDriver = () => {
	return useSelector(state => state.driver.driver)
}
export const useDriverToken = () => useSelector(state => state.driver.driverToken)
export const setDriver = data => store.dispatch(_setDriver(data))
export const removeDriver = () => store.dispatch(_removeDriver())
export const setDriverToken = data => store.dispatch(_setDriverToken(data))

export default driver.reducer
